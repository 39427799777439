import { Controller } from '@hotwired/stimulus'

import { digProperty } from '../src/mixins/dig'

// Displays an (optional) string message from a Live Drone or Live Remote
// Defaults to the default value if the message is nil or not provided
export default class extends Controller {
  static values = {
    field: String,
    default: String,
    tooltipLookup: Object, // This is the tooltip text for the icon, the structure is {<name>: <title>}, such as {"0": "very weak"...} which is used for signal strength
    classLookup: Object
  }

  connect () {}

  disconnect () {}

  update ({ detail }) {
    const value = digProperty(this.fieldValue, detail)
    const iconClass = this.classLookupValue[value]

    if (value != null && iconClass) {
      // if there is an icon class for the value, we display the icon
      this.element.className = iconClass

      if (this.tooltipLookupValue && this.tooltipLookupValue[value]) {
        // if tooltip is provided, the title is set to the value of the tooltip
        const title = this.tooltipLookupValue[value]
        this.element.dataset.originalTitle = title
      }

      this.element.innerHTML = ''
    } else {
      // if the value is not provided, or the value is unexpected, we display the default values
      this.element.innerHTML = this.defaultValue
    }
  }
}
