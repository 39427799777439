import { Controller } from '@hotwired/stimulus'

import 'timepicker/jquery.timepicker'

export default class extends Controller {
  connect () {
    $(this.element).timepicker({
      timeFormat: 'g:i A',
      scrollDefault: '8:00 AM'
    })
  }

  disconnect () {
    $(this.element).timepicker('remove')
  }
}
