import { Controller } from '@hotwired/stimulus'

import { withStatusPolling } from '../../src/mixins/with_status_polling'

/**
 * Retrieves the Dock Health from the Drone Status Service
 * and broadcasts it to the page (via a healthUpdate event)
 */
export default class extends Controller {
  static values = {
    refreshRate: {
      type: Number,
      default: 10_000
    },
    url: String
  }

  connect () {
    withStatusPolling(this, this.urlValue, this.refreshRateValue, this.dispatchHealthUpdate.bind(this))
  }

  dispatchHealthUpdate (res) {
    const dockHealth = res.data || {} // unknown docks return null, do not allow null to propagate
    const dockMeta = res.meta || { live_status: false } // unknown docks are not live

    // Broadcast a healthUpdate event to the page
    const liveDockHealth = dockMeta.live_status ? dockHealth : {}
    this.dispatch('healthUpdate', { detail: { dock_health: liveDockHealth } })
  }
}
