import { Controller } from '@hotwired/stimulus'

import { withStatusPolling } from '../../src/mixins/with_status_polling'

/**
 * Retrieves the Dock Status from the Drone Status Service
 * and broadcasts it to the page (via a statusUpdate event)
 */
export default class extends Controller {
  static targets = ['listener']
  static values = {
    refreshRate: {
      type: Number,
      default: 1_000
    },
    url: String
  }

  connect () {
    withStatusPolling(this, this.urlValue, this.refreshRateValue, this.dispatchStatusUpdate.bind(this))
  }

  // dockStatus is an object like this:
  // {
  //   elevation: "0.0",
  //   height: "106.658226",
  //   last_timestamp: 1690860840172,
  //   position: {latitude: '-33.960437', longitude: '150.725679'},
  //   serial_number: "abcdedf"
  // }
  dispatchStatusUpdate (res) {
    const dockStatus = res.data || {} // unknown docks return null, do not allow null to propagate
    const dockMeta = res.meta || { live_status: false } // unknown docks are not live

    // Broadcast a liveUpdate event to the page
    const liveDockStatus = dockMeta.live_status ? dockStatus : {}
    this.dispatch('liveUpdate', { detail: { dock_status: liveDockStatus, meta: dockMeta } })
  }
}
